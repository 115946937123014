import React from 'react'
import { HeadFC } from 'gatsby'
import { Layout } from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { SEO } from '../components/SEO'

const TeachingPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <h2>Propositions d'enseignement</h2>
        <h3>Ateliers</h3>
        <h4>Ateliers découverte du bharata natyam</h4>
        <p>
          En deux ou trois heures : un peu d’histoire de l’art et de mythologie, et beaucoup de pratique, Mon approche
          mêle la tradition de l’enseignement classique tel que je l’ai reçu, et une initiation aux questions
          anatomiques que soulève la technique du bharata natyam dans ses postures et mouvements de base.
        </p>

        <div className="image-text">
          <div className="image">
            <StaticImage
              src={'../images/teaching-1.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="workshops"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Anne-Claire Hélard</figcaption>
          </div>
          <div>
            <h4>Ateliers pour danseurs</h4>
            <p>
              Je prends plaisir à proposer des initiations au bharata natyam dans les écoles de danse : les danseurs
              d’autres styles découvrent ainsi les spécificités du bharata natyam (posture, travail des mains, de
              l’expression du visage). Cela ouvre souvent sur d’autres manières de travailler leur propre technique.
            </p>
          </div>
        </div>

        <div className="image-text">
          <div>
            <h4>Ateliers parents-enfants</h4>
            <p>
              Une heure en duo parent-enfant, pour découvrir des personnages de la mythologie hindoue tout en entrant
              par le corps dans la pratique du bharata natyam. C’est l’occasion de se parler, de se toucher, de se
              regarder, de rire et d’apprendre ensemble.
            </p>
          </div>
          <div className="image">
            <StaticImage
              src={'../images/teaching-2.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="atelier2"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Irénée de Poulpiquet</figcaption>
          </div>
        </div>
        <h2>Cours</h2>
        <div className="image-text">
          <div className="image">
            <StaticImage
              src={'../images/teaching-3.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="workshops"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
            <figcaption>Photo : Anne-Claire Hélard</figcaption>
          </div>
          <div>
            <p>
              Je donne un cours mensuel à St Herblain, proche de Nantes, pour l'association Sandanatyam. Le but est
              d'entrer dans une pratique régulière du bharata natyam, d'en comprendre les bases techniques en intégrant
              des notions théoriques d'histoire de la danse, de mythologie et de rythmique.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeachingPage
export const Head: HeadFC = () => <SEO title="Lakshmi - Enseignement" pathname="/enseignement"></SEO>
